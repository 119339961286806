<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in
          customer === 'lead'
            ? leadTabs
            : customer === 'client'
              ? clientTabs : archiveTabs"
        :key="i"
      >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>

      <v-tabs-items
        v-model="mainTab"
        class="transparent"
      >
        <v-tab-item>
          <leads
            v-if="customer === 'lead'"
            title="Leads"
            url="customer/leads"
          />
          <clients
            v-else-if="customer === 'client'"
            title="Clients"
            url="customer/clients"
          />
          <archives v-else />
        </v-tab-item>

        <v-tab-item>
          <add-form
            :title="`Add new ${customer}`"
            :is-lead="customer === 'lead'"
          />
        </v-tab-item>
      </v-tabs-items>
    </base-material-tabs>
  </v-container>
</template>

<script>
  export default {
    components: {
      Clients: () => import('./Clients'),
      Leads: () => import('./Leads'),
      Archives: () => import('./Archives'),
      AddForm: () => import('./details/AddForm'),
    },

    props: {
      customer: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      mainTab: 0,
      clientTabs: [
        { text: 'Clients', icon: 'mdi-shield-account' },
        { text: 'Add New Client', icon: 'mdi-plus' },
      ],
      leadTabs: [
        { text: 'Leads', icon: 'mdi-account-multiple-plus' },
        { text: 'Add New Lead', icon: 'mdi-plus' },
      ],
      archiveTabs: [
        { text: 'Archives', icon: 'mdi-shield-remove-outline' },
        { text: 'Add New Archive', icon: 'mdi-plus' },
      ],
    }),
  }
</script>
